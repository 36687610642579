import styled from 'styled-components';

import bannerDashboard from '~/assets/banners/banner-dashboard.svg';
import bannerDashboardMobile from '~/assets/banners/banner-dashboard-mobile.svg';

export const Container = styled.div`
  .box {
    border-radius: 13px;
    border: 1px solid #444;
    background: #2d2d2d;

    .detail {
      width: 20.35px;
      height: 20.35px;
      border-radius: 8px;
    }

    .detail.bg-yellow {
      background: #17c672;
    }

    .detail.bg-orange {
      background: #0e5f38;
    }
  }
`;

export const Banner = styled.div`
  background-color: #001a10;
  background-image: url(${bannerDashboardMobile});
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;

  p {
    font-size: 18px;
    color: #a2a2a2;
  }

  @media screen and (min-width: 768px) {
    background-image: url(${bannerDashboard});
    background-position: center right;
  }

  @media screen and (min-width: 992px) {
    background-image: url(${bannerDashboard});
    background-position: -150px center;
  }

  @media screen and (min-width: 1200px) {
    background-image: url(${bannerDashboard});
    background-position: center right;
  }
`;
