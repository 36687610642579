/* eslint-disable no-restricted-syntax */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { RiBuildingLine, RiCloseLine } from 'react-icons/ri';
import { CgUser } from 'react-icons/cg';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container, Modal, Options } from './styles';
import Table, { IColumn } from '~/components/Table';
import Loading from '~/components/Loading';

interface ILeadResponse {
  id: number;
  name: string;
  email: string;
  company: string;
  message: string;
}

interface ILeadData {
  data: ILeadResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface ILead {
  id: string;
  lead_id: number;
  name: string;
  email: string;
  company: string;
  message: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const Leads: React.FC = () => {
  const [leads, setLeads] = useState<ILead[]>([]);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [leadSelected, setLeadSelected] = useState({} as ILead);
  const [show, setShow] = useState(false);

  const loadLeads = useCallback(async (pageData, search = '') => {
    const response = await api.get<ILeadData>('leads', {
      params: { page: pageData, search },
    });

    const data = response.data.data.map<ILead>((lead) => {
      return {
        id: lead.id.toString().padStart(5, '0'),
        lead_id: lead.id,
        name: lead.name,
        email: lead.email,
        company: lead.company,
        message: lead.message,
      };
    });

    setLeads(data);
    setTableData({
      from: response.data.from,
      to: response.data.to,
      total: response.data.total,
      current_page: response.data.current_page,
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    loadLeads(page).finally(() => setLoading(false));
  }, [loadLeads, page]);

  const handleClickOutside = useCallback((event) => {
    const swalElements = document.getElementsByClassName(
      'swal2-container'
    ) as unknown as HTMLElement[];
    const btnElements = document.getElementsByClassName(
      'btn-option'
    ) as unknown as HTMLElement[];
    const elements = document.getElementsByClassName(
      'options'
    ) as unknown as HTMLElement[];
    let close = true;
    for (const element of elements) {
      for (const btnElement of btnElements) {
        if (
          btnElement.contains(event.target) ||
          element.contains(event.target)
        ) {
          close = false;
        }

        if (swalElements.length > 0) {
          for (const swalElement of swalElements) {
            if (swalElement.contains(event.target)) {
              close = false;
            }
          }
        }
      }
    }

    if (close) {
      setLeadSelected({} as ILead);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleClickDots = useCallback((lead: ILead) => {
    setLeadSelected((state) => (state.id === lead.id ? ({} as ILead) : lead));
  }, []);

  const handleClickDeleteLead = useCallback(() => {
    Swal.fire({
      title: 'Deseja deletar esse lead?',
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      confirmButtonColor: '#17C672',
      cancelButtonColor: '#a6a6a6',
      cancelButtonText: 'Não',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await api.delete(`leads/${leadSelected.lead_id}`);

          loadLeads(page);
          setLeadSelected({} as ILead);

          Toast.fire({
            icon: 'success',
            title: 'Lead deletado!',
          });
        }
      })
      .catch(() => {
        Swal.fire(
          'Opss...',
          'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
          'error'
        );
      });
  }, [loadLeads, leadSelected.lead_id, page]);

  const handleClickShowLead = useCallback(() => {
    setShow(true);
  }, []);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Empresa',
        selector: 'company',
      },
      {
        name: 'Mensagem',
        selector: 'message',
        cell: (row: ILead) => (
          <div className="message-content">{row.message}</div>
        ),
      },
      {
        name: '',
        selector: 'id',
        cell: (row: ILead) => (
          <div className="options-group position-relative">
            <button
              type="button"
              className="d-none d-lg-flex align-items-center justify-content-center border-0 bg-transparent btn-option"
              onClick={() => handleClickDots(row)}
            >
              <BsThreeDotsVertical size={24} color="#bbbbbb" />
            </button>
            <Options className="options" active={leadSelected.id === row.id}>
              <button type="button" onClick={handleClickShowLead}>
                Ver lead
              </button>
              <button type="button" onClick={handleClickDeleteLead}>
                Deletar lead
              </button>
            </Options>
          </div>
        ),
      },
    ],
    [
      handleClickDeleteLead,
      handleClickDots,
      handleClickShowLead,
      leadSelected.id,
    ]
  );

  const handleChangePage = useCallback((pageData) => {
    setPage(pageData);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-between my-5">
            <h1 className="h2">Leads</h1>
          </div>
          <div className="col-12">
            <Table
              columns={columns}
              data={leads}
              toData={tableData.to}
              fromData={tableData.from}
              totalData={tableData.total}
              selectedPage={tableData.current_page}
              className="table-leads mt-5"
              pagination
              onChangePage={handleChangePage}
            />
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} close size="lg">
        <Modal.Header className="border-0 px-5 d-flex align-items-center justify-content-between">
          <h3 className="mb-0">Lead {leadSelected.id}</h3>
          <button
            type="button"
            className="border-0 bg-transparent"
            onClick={handleClose}
          >
            <RiCloseLine size={44} color="#DADADA" />
          </button>
        </Modal.Header>
        <Modal.Body className="px-5 pb-5">
          <div className="row">
            <div className="col-lg-6">
              <p className="label mb-2">Nome:</p>
              <div className="d-flex align-items-center">
                <CgUser size={24} color="FEFEFE" className="me-1" />
                <p className="mb-0 lead-data">{leadSelected.name}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <p className="label mb-2">E-mail:</p>
              <div className="d-flex align-items-center">
                <CgUser size={24} color="FEFEFE" className="me-1" />
                <p className="mb-0 lead-data">{leadSelected.email}</p>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <p className="label mb-2">Empresa:</p>
              <div className="d-flex align-items-center">
                <RiBuildingLine size={24} color="FEFEFE" className="me-1" />
                <p className="mb-0 lead-data">{leadSelected.company}</p>
              </div>
            </div>
            <div className="col-lg-6 mt-5">
              <p className="label mb-2">Mensagem:</p>
              <p className="lead-data">{leadSelected.message}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Loading active={loading} />
    </Container>
  );
};

export default Leads;
