import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';

import { RiArrowLeftSLine } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo.svg';
import emailSent from '~/assets/animations/email-sent.json';
import signinBackground from '~/assets/banners/signin-background.svg';

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [mailSent, setMailSent] = useState(false);

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setMailSent(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
      }
    }
  }, []);

  return (
    <Container>
      <div className="container pt-lg-5 pb-lg-4">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-5 pt-5">
            <Link
              to={`${process.env.PUBLIC_URL}`}
              className="d-flex d-lg-none align-items-end mb-5"
            >
              <img src={logo} alt="Logo" />
            </Link>
            {!mailSent ? (
              <>
                <div className="w-100">
                  <Link
                    to={`${process.env.PUBLIC_URL}`}
                    className="mb-3 d-block"
                  >
                    <RiArrowLeftSLine size={44} color="#202020" />
                  </Link>
                  <h1>Esqueceu a senha?</h1>
                  <p className="h5 fw-light">
                    Para restaurar a senha, insira seu e-mail
                  </p>
                </div>
                <Form ref={formRef} onSubmit={handleSubmit} className="mt-5">
                  <label className="d-block w-100 mb-3">
                    <span className="fw-medium mb-2 d-block">E-mail</span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Insira seu e-mail"
                    />
                  </label>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill w-100 py-2 mt-5"
                  >
                    Continuar
                  </button>
                </Form>
              </>
            ) : (
              <>
                <div className="w-100">
                  <h1>E-mail enviado com sucesso</h1>
                  <p className="h5 fw-light">
                    Verifique sua caixa de entrada, <br />
                    spam ou lixo eletrônico
                  </p>
                </div>
                <div className="w-100">
                  <div className="my-5 pt-4 pb-3 pe-none">
                    <Lottie
                      options={{
                        animationData: emailSent,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      width={206}
                      height={176}
                    />
                  </div>
                  <Link
                    to={`${process.env.PUBLIC_URL}`}
                    className="btn btn-primary rounded-pill py-2 w-100"
                  >
                    Ir para o login
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <img src={signinBackground} alt="" className="banner d-none d-lg-block" />
    </Container>
  );
};

export default ForgotPassword;
