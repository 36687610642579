import styled from 'styled-components';
import { darken } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IOptions {
  active: boolean;
}

export const Container = styled.div`
  .table-leads {
    padding-top: 0;
    background-color: transparent;

    > div {
      :first-child {
        background-color: #2d2d2d;
        border-color: #2d2d2d;
      }

      :last-child {
        select {
          background-color: transparent;
          color: #fff;
        }
      }

      .content {
        overflow: auto hidden;
        padding-top: 10px;

        .tb-header {
          border: none;

          button {
            color: #fff;
          }
        }

        .tb-body {
          background-color: #2d2d2d;

          .tb-row {
            transition-duration: 0.3s;

            + .tb-row {
              border-top: 1px solid #fff;
            }
          }

          div {
            color: #f9f9f9;

            > p {
              color: #f9f9f9;
            }
          }
        }

        .tb-header,
        .tb-body {
          .tb-row {
            > button,
            > div {
              width: 100%;
            }
          }
        }
      }

      :after,
      :before {
        border: none;
      }

      :nth-child(2) {
        select {
          color: #bbbbbb;
        }

        small {
          color: #bbbbbb;
        }

        button {
          background-color: transparent;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .table-leads {
      > div {
        .content {
          .tb-body {
            .tb-row {
              border-radius: 16px;

              :hover {
                background-color: ${darken(0.03, '#2d2d2d')};
              }

              + .tb-row {
                border: unset;
              }
            }
          }

          .tb-header,
          .tb-body {
            .tb-row {
              > button:nth-child(1),
              > div:nth-child(1) {
                width: 100px;
              }

              > button:nth-child(2),
              > div:nth-child(2) {
                width: 200px;
              }

              > button:nth-child(3),
              > div:nth-child(3) {
                width: 300px;
              }

              > button:nth-child(4),
              > div:nth-child(4) {
                width: 200px;
              }

              > button:nth-child(5),
              > div:nth-child(5) {
                width: 300px;
                .message-content {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              > button:nth-child(6),
              > div:nth-child(6) {
                width: 100px;

                .options-group {
                  width: max-content;
                  margin-left: auto;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .table-suppliers {
      > div {
        .content {
          overflow: unset;
        }
      }
    }
  }
`;

export const Options = styled.div<IOptions>`
  transition-duration: 0.3s;
  display: flex;
  justify-content: space-around;

  a,
  button {
    background: transparent;
    border: none;
    text-align: center;
    color: #fff;
    padding: 8px 0;
  }

  @media screen and (min-width: 992px) {
    opacity: ${(props) => (props.active ? '1' : '0')};
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
    background: #303030;
    border: 2px solid #e4e4e466;
    width: max-content;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    justify-content: unset;

    a,
    button {
      + button,
      + a {
        border-top: 1px solid #e4e4e4;
      }
    }

    :after {
      content: '';
      width: 15px;
      height: 15px;
      background: #303030;
      border-right: 2px solid #e4e4e466;
      border-top: 2px solid #e4e4e466;
      position: absolute;
      top: 50%;
      right: -4px;
      transform: rotateZ(45deg) translateY(-50%);
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border: none;
    background: #4e4e4e;
    border-radius: 16px;

    .label {
      color: #b7b7b7;
    }

    .lead-data {
      width: calc(100% - 28px);
    }
  }
`;
