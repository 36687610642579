import React from 'react';

import { Container, Content } from './styles';
import SideNavbar from '~/components/SideNavbar';

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <Container className="d-lg-flex justify-content-end">
      <SideNavbar />
      <Content>{children}</Content>
    </Container>
  );
};

export default DefaultLayout;
