import styled from 'styled-components';

export const Container = styled.div`
  .preview {
    width: 100%;
    height: 250px;
    background-color: #2d2d2d;
    border-radius: 8px;
    overflow: hidden;
  }
`;
