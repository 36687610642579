import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { TbHome, TbLogout } from 'react-icons/tb';
import { CgFileDocument, CgUserList } from 'react-icons/cg';
import { WhiteSpace, Container, Menu } from './styles';

import logo from '~/assets/logos/logo-short.svg';
import { useAuth } from '~/hooks/Auth';

const SideNavbar: React.FC = () => {
  const location = useLocation();
  const { signOut } = useAuth();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'auto';
  }, [active]);

  return (
    <>
      <WhiteSpace className="d-block d-lg-none" />
      <Container className="px-lg-4 py-lg-5 d-flex flex-column">
        <div className="mb-lg-5 p-4 p-lg-0 d-flex align-items-center justify-content-between">
          <Link
            to={`${process.env.PUBLIC_URL}/dashboard`}
            className="d-flex align-items-end justify-content-center logo"
          >
            <img src={logo} alt="Logo" className="w-100 w-lg-75" />
          </Link>
          <button
            type="button"
            className={`bg-transparent d-lg-none border-0 mx-3 btn-menu ${
              active ? 'active' : ''
            }`}
            onClick={() => setActive(!active)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <Menu active={active} className="p-3 p-lg-0">
          <div className="d-flex flex-column justify-content-between">
            <div>
              <NavLink
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className="d-flex align-items-center p-3 mb-4 item-menu"
                activeClassName="active"
              >
                <TbHome size={20} className="me-3" /> <span>Dashboard</span>
              </NavLink>
              <NavLink
                to={`${process.env.PUBLIC_URL}/portifolio`}
                className="d-flex align-items-center p-3 mb-4 item-menu"
                activeClassName="active"
              >
                <CgFileDocument size={20} className="me-3" />
                <span>Portifólio</span>
              </NavLink>
              <NavLink
                to={`${process.env.PUBLIC_URL}/leads`}
                className="d-flex align-items-center p-3 mb-4 item-menu"
                activeClassName="active"
              >
                <CgUserList size={20} className="me-3" /> <span>Leads</span>
              </NavLink>
            </div>
            <button
              type="button"
              className="btn text-start d-flex align-items-center p-3 item-menu w-100"
              onClick={() => signOut()}
            >
              <TbLogout size={20} className="me-3" /> <span>Sair</span>
            </button>
          </div>
        </Menu>
      </Container>
    </>
  );
};

export default SideNavbar;
