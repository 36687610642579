import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Container, Banner } from './styles';

import logo from '~/assets/logos/logo.svg';

const Dashboard: React.FC = () => {
  const [qtyProjetcs, setQtyProjects] = useState('00');
  const [qtyLeads, setQtyLeads] = useState('00');

  useEffect(() => {
    api.get('projects').then((response) => {
      setQtyProjects(response.data.total.toString().padStart(2, '0'));
    });
    api.get('leads').then((response) => {
      setQtyLeads(response.data.total.toString().padStart(2, '0'));
    });
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-5 pb-5">
            <Banner className="p-5">
              <div className="pb-5 pt-lg-5 mb-5">
                <img src={logo} alt="Logo" className="mb-4" />
                <h1 className="h2 mt-3">Olá</h1>
                <p>Bem-vindo (a) à plataforma</p>
              </div>
            </Banner>
          </div>
          <div className="col-lg-6 mb-3 mb-lg-0">
            <div className="box p-4">
              <div className="detail bg-yellow" />
              <p className="display-4 fw-semibold mb-0 mb-lg-3 py-4">
                {qtyProjetcs}
              </p>
              <p className="mb-0">
                Quantidade de <br className="d-none d-lg-block" />
                <b>Projetos</b>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="box p-4">
              <div className="detail bg-orange" />
              <p className="display-4 fw-semibold mb-0 mb-lg-3 py-4">
                {qtyLeads}
              </p>
              <p className="mb-0">
                Quantidade de <br className="d-none d-lg-block" />
                <b>Leads</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
