import React, { useCallback, useMemo, useRef, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import Toast from '~/utils/toast';

import { Container } from './styles';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';

interface IFormData {
  title: string;
  category: string;
  link: string;
}

const New: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [link, setLink] = useState('');

  const options = useMemo<IOption[]>(
    () => [
      {
        id: 'dashboards',
        value: 'Dashboards',
        selected: true,
      },
      {
        id: 'analysis',
        value: 'Análise de dados',
        selected: false,
      },
      {
        id: 'projects',
        value: 'Projetos personalizados',
        selected: false,
      },
    ],
    []
  );

  const handleChangeLink = useCallback((e) => {
    setLink(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('O título é obrigatório'),
          category: Yup.string().required('A categoria é obrigatória'),
          link: Yup.string()
            .url('Envie um link válido')
            .required('A link é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          title: data.title,
          category: data.category,
          link: data.link,
        };

        await api.post('projects', formData);

        Toast.fire({
          icon: 'success',
          title: 'Projeto criado com sucesso!',
        });

        history.push(`${process.env.PUBLIC_URL}/portifolio`);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [history]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <Form ref={formRef} onSubmit={handleSubmit} className="row">
          <div className="col-12 mb-5">
            <div className="d-flex align-items-center">
              <Link
                to={`${process.env.PUBLIC_URL}/portifolio`}
                className="d-block me-1"
              >
                <RiArrowLeftSLine size={44} color="#707070" />
              </Link>
              <h1 className="h2 mb-0">Novo projeto</h1>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="px-2 pb-4 p-lg-5 h-100">
              <div>
                <label className="d-block w-100 mb-4">
                  <span className="fw-medium mb-2 d-block">
                    Título do projeto
                  </span>
                  <Input name="title" placeholder="Digite  aqui" />
                </label>
                <label className="d-block w-100 mb-4">
                  <span className="fw-medium mb-2 d-block">Categoria</span>
                  <Select
                    name="category"
                    placeholder="Selecione"
                    options={options}
                  />
                </label>
                <label className="d-block w-100">
                  <span className="fw-medium mb-2 d-block">Link</span>
                  <Input
                    name="link"
                    placeholder="Digite o link aqui"
                    onChange={handleChangeLink}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="px-2 p-lg-5">
              <div>
                <label className="d-block w-100">Preview</label>
                <div className="preview mt-3">
                  {link && (
                    <iframe
                      src={link}
                      title={link}
                      className="w-100"
                      height={250}
                    />
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-end mt-5">
                <button
                  type="submit"
                  className="btn btn-primary px-5 py-2 rounded-pill w-100 w-lg-auto"
                >
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default New;
