import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';
import ChangePassword from '~/pages/ChangePassword';
import Dashboard from '~/pages/Dashboard';
import Projects from '~/pages/Projects';
import ProjectNew from '~/pages/Projects/New';
import ProjectUpdate from '~/pages/Projects/Update';
import Leads from '~/pages/Leads';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/esqueci-minha-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/recuperar-senha/:token`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        exact
        component={Dashboard}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portifolio`}
        exact
        component={Projects}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portifolio/novo`}
        exact
        component={ProjectNew}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portifolio/:slug`}
        exact
        component={ProjectUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/leads`}
        exact
        component={Leads}
        isPrivate
      />
    </Switch>
  );
};

export default routes;
